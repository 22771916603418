import { CenterDemos, TabsDemos, RingProgressDemos, BadgeDemos, NumberInputDemos, HooksDemos } from '@mantine/demos';
import * as React from 'react';
export default {
  CenterDemos,
  TabsDemos,
  RingProgressDemos,
  BadgeDemos,
  NumberInputDemos,
  HooksDemos,
  React
};