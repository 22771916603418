import { AccordionDemos, SelectDemos, AutocompleteDemos, JsonInputDemos, SliderDemos, AffixDemos, CalendarDemos, RangeCalendarDemos, MonthDemos, DatePickerDemos, TimeInputDemos, DateRangePickerDemos } from '@mantine/demos';
import * as React from 'react';
export default {
  AccordionDemos,
  SelectDemos,
  AutocompleteDemos,
  JsonInputDemos,
  SliderDemos,
  AffixDemos,
  CalendarDemos,
  RangeCalendarDemos,
  MonthDemos,
  DatePickerDemos,
  TimeInputDemos,
  DateRangePickerDemos,
  React
};