import { TimelineDemos, DialogDemos, ListDemos, ChipsDemos, PaginationDemos, TimeRangeInputDemos, RichTextEditorDemos } from '@mantine/demos';
import * as React from 'react';
export default {
  TimelineDemos,
  DialogDemos,
  ListDemos,
  ChipsDemos,
  PaginationDemos,
  TimeRangeInputDemos,
  RichTextEditorDemos,
  React
};