import { PasswordInputDemos, MultiSelectDemos, StepperDemos, TransferListDemos, DatePickerDemos, DateRangePickerDemos, ModalsDemos, HooksDemos } from '@mantine/demos';
import * as React from 'react';
export default {
  PasswordInputDemos,
  MultiSelectDemos,
  StepperDemos,
  TransferListDemos,
  DatePickerDemos,
  DateRangePickerDemos,
  ModalsDemos,
  HooksDemos,
  React
};