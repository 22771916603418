import { RingProgressDemos, NumberInputDemos, StepperDemos, ProgressDemos, SwitchDemos, TransferListDemos, PrismDemos, RichTextEditorDemos } from '@mantine/demos';
import * as React from 'react';
export default {
  RingProgressDemos,
  NumberInputDemos,
  StepperDemos,
  ProgressDemos,
  SwitchDemos,
  TransferListDemos,
  PrismDemos,
  RichTextEditorDemos,
  React
};