import { ExternalLinkIcon } from '@modulz/radix-icons';
import { Anchor, Center } from '@mantine/core';
import { AppShellDemos } from '@mantine/demos';
import * as React from 'react';
export default {
  ExternalLinkIcon,
  Anchor,
  Center,
  AppShellDemos,
  React
};