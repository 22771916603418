exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-shell-demo-tsx": () => import("./../../../src/pages/app-shell-demo.tsx" /* webpackChunkName: "component---src-pages-app-shell-demo-tsx" */),
  "component---src-pages-assets-tsx": () => import("./../../../src/pages/assets.tsx" /* webpackChunkName: "component---src-pages-assets-tsx" */),
  "component---src-pages-getting-started-tsx": () => import("./../../../src/pages/getting-started.tsx" /* webpackChunkName: "component---src-pages-getting-started-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mdx-slug-tsx": () => import("./../../../src/pages/{Mdx.slug}.tsx" /* webpackChunkName: "component---src-pages-mdx-slug-tsx" */),
  "component---src-pages-theme-builder-tsx": () => import("./../../../src/pages/theme-builder.tsx" /* webpackChunkName: "component---src-pages-theme-builder-tsx" */)
}

