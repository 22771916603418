import { MediaQueryDemos, CollapseDemos, AppShellDemos, SelectDemos, BoxDemos, SkeletonDemos, HooksDemos, CreateStylesDemos, ThemeDemos } from '@mantine/demos';
import * as React from 'react';
export default {
  MediaQueryDemos,
  CollapseDemos,
  AppShellDemos,
  SelectDemos,
  BoxDemos,
  SkeletonDemos,
  HooksDemos,
  CreateStylesDemos,
  ThemeDemos,
  React
};