import { DEFAULT_THEME } from '@mantine/core';
import { PaperDemos, ThemeDemos } from '@mantine/demos';
import { ThemeColors } from "../../../../src/components/ThemeColors/ThemeColors";
import * as React from 'react';
export default {
  DEFAULT_THEME,
  PaperDemos,
  ThemeDemos,
  ThemeColors,
  React
};