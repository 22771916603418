import { DEFAULT_THEME } from '@mantine/core';
import { GridDemos, SimpleGridDemos, AppShellDemos, MediaQueryDemos, CreateStylesDemos } from '@mantine/demos';
import * as React from 'react';
export default {
  DEFAULT_THEME,
  GridDemos,
  SimpleGridDemos,
  AppShellDemos,
  MediaQueryDemos,
  CreateStylesDemos,
  React
};