import { AccordionDemos, AvatarDemos, MediaQueryDemos, SelectDemos, MultiSelectDemos, DropzoneDemos, HooksDemos, CreateStylesDemos } from '@mantine/demos';
import * as React from 'react';
export default {
  AccordionDemos,
  AvatarDemos,
  MediaQueryDemos,
  SelectDemos,
  MultiSelectDemos,
  DropzoneDemos,
  HooksDemos,
  CreateStylesDemos,
  React
};