import { SegmentedControlDemos, PopoverDemos, DividerDemos, GroupDemos, GridDemos, HooksDemos } from '@mantine/demos';
import * as React from 'react';
export default {
  SegmentedControlDemos,
  PopoverDemos,
  DividerDemos,
  GroupDemos,
  GridDemos,
  HooksDemos,
  React
};